<template>
  <div id="rechargeRecord">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <el-date-picker
              v-model="filtrate.time"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getList(1)"
              align="right"
            >
            </el-date-picker>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="recordTime" label="充值时间" align="center">
        </el-table-column>
        <el-table-column prop="recordType" label="类型" align="center">
        </el-table-column>
        <el-table-column prop="amountChange" label="金额" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filtrate: {
        time: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    reset() {
      this.filtrate = {
        time: "",
      };
      this.table.page = 1;
      this.getList();
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      let params = {
        userId: this.$route.params.id,
        pageNum: page,
        pageSize: this.table.pageSize,
      };
      if (this.filtrate.time) {
        params.startTime = this.filtrate.time[0];
        params.endTime = this.filtrate.time[1];
      }
      try {
        this.table.loading = true;
        let res = await this.$http.post("/parking/shop/charge/history", params);
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.recordTime = this.$moment(item.recordTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#rechargeRecord {
}
</style>
